import { Link } from 'react-router-dom';
import './Consultation.scss';

const Consultation: React.FC = () => {

    return (
        <div className="container">
            <section className="integration-consultation">
                <a href="#" className="integration-consultation-booklet">Скачать буклет с информацией</a>
                <div className="integration-consultation-product">
                    <div className="integration-consultation-text">
                        <h2>Получить консультацию</h2>
                        <p>Если у Вас есть дополнительные вопросы, напишите письмо на электронную почту.  </p>
                    </div>
                    <Link to='/contacts' className="integration-consultation-contacts">Контакты</Link>
                </div>
            </section>
        </div>
    )
}

export default Consultation;