import { IList } from "./List";

interface IListDetailsProps {
    el: IList;
}

const ListDetails: React.FC<IListDetailsProps> = ({ el }) => {
    return (
      <div className="list-details">
        <h2 className="list-header">{el.title}</h2>
        {el.description.map((paragraph, index) => (
            <p key={index}  className="list-description">{paragraph}</p>
        ))}
      </div>
    );
};

export default ListDetails;