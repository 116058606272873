import ReactPlayer from "react-player";
import './Presentation.scss';
import { Link } from "react-router-dom";

const Presentation: React.FC = () => {

    const videoUrl: string = 'https://youtu.be/PSoogrYWH3c?si=D009O-XYEDcgLANY';

    return (
        <div className="container">
            <section className="presentation">
                <div className="presentation-text">
                    <h2 className="presentation-header">Презентация технологии</h2>
                    <Link to="/solutions" className="presentation-btn">Подробнее</Link>
                </div>
                <div className="presentation-video">
                    <ReactPlayer className='react-player' url={videoUrl} loop playing={true}/>
                </div>
            </section>
        </div>
    )
}

export default Presentation;