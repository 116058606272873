import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import { IPhotos } from "./data";
import './Photo.scss';
import { Link } from "react-router-dom";

interface IPhotoProps {
    data: IPhotos[]
}

const Photo: React.FC<IPhotoProps> = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const [animationClass, setAnimationClass] = useState('fade-in');

    const handlePrev = () => {
      setCurrentIndex(currentIndex === 0 ? data.length - 1 : currentIndex - 1);
      setAnimationClass('fade-out');
      setTimeout(() => setAnimationClass('fade-in'), 500); // Задержка перед следующей анимацией (500 миллисекунд)
  };

  const handleNext = () => {
      setCurrentIndex(currentIndex === data.length - 1 ? 0 : currentIndex + 1);
      setAnimationClass('fade-out');
      setTimeout(() => setAnimationClass('fade-in'), 500); // Задержка перед следующей анимацией (500 миллисекунд)
  };

  useEffect(() => {
      const intervalId = setInterval(() => {
          setCurrentIndex(prevIndex => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
          setAnimationClass('fade-out');
          setTimeout(() => setAnimationClass('fade-in'), 500); // Задержка перед следующей анимацией (500 миллисекунд)
      }, 10000);

      return () => clearInterval(intervalId);
  }, [data.length]);


  return (
    <section className="main-photo">
        <button className="main-btn main-btn-prev" onClick={handlePrev}><FaChevronLeft/></button>
        <div className={`${data[currentIndex].class} photo`}>
            <div className="container">
              <div className="photo-filling">
                <h2 className={`photo-header ${animationClass}`}>
                    {data[currentIndex].title}<br/>
                    <span>{data[currentIndex].name}</span>
                </h2>
                <Link to="/solutions" className="more">Подробнее</Link>
              </div>
            </div>
        </div>
        <button className="main-btn main-btn-next" onClick={handleNext}><FaChevronRight /></button>
    </section>
  );
};

export default Photo;