import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Main from './pages/Main/Main';
import Call from './components/Call/Call';
import About from './pages/About/About';
import Intergation from './pages/Integration/Integration';
import Contact from './pages/Contacts/Contact';
import ScrollToTop from './components/Scroll/ScrollTop';
import Solutions from './pages/Solutions/Solutions';

function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header/>
      <Routes>
        <Route path='/' element={<Main />}/>
        <Route path='/about' element={<About />}/>
        <Route path='/solutions' element={<Solutions />}/>
        <Route path='/integration' element={<Intergation />}/>
        <Route path='/contacts' element={<Contact />}/>
      </Routes>
      <Call/>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
