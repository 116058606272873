import videowall from '../../../assets/images/products-videowall.jpg';
import polycamera from '../../../assets/images/products-polycamera.png';
import viewStation from '../../../assets/images/products-viewStation.png';
import './Products.scss';
import { Link } from 'react-router-dom';

const Products: React.FC = () => {
    return (
        <div className="container">
            <section className="products">
                <div className="product">
                    <img src={videowall} alt="" className="product-img" />
                    <h2 className="product-header">Ommatid | IP Videowall</h2>
                    <p className="product-text">Решение для построения видеостены, позволяет подключать до 8 мониторов, поддержкой аппаратного декодирования видео. Видеостена позволяет работать с Onvif и RTSP потоками независимо от производителя. </p>
                    <Link to="/solutions" className="product-link">ПОДРОБНЕЕ</Link>
                </div>
                <div className="product">
                    <img src={polycamera} alt="" className="product-img" id='polycamera'/>
                    <h2 className="product-header">Ommatid | Polycamera</h2>
                    <p className="product-text">Мультифокальная панорамная камера для наблюдению за большими площадями, в высоком разрешении. </p>
                    <Link to="/solutions" className="product-link">ПОДРОБНЕЕ</Link>
                </div>
                <div className="product">
                    <img src={viewStation} alt="" className="product-img"/>
                    <h2 className="product-header">Ommatid | View Station</h2>
                    <p className="product-text">Текст, который содержит краткое описание той или иной услуги, которую мы предлагаем. </p>
                    <Link to="/solutions" className="product-link">ПОДРОБНЕЕ</Link>
                </div>
            </section>
        </div>
    )
}

export default Products;